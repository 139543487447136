import PlusSvg from '~/components/icons/plus.svg?react'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '~/components/ui/collapsible'
import { SITE_TITLE } from '~/constants'

const faqs: {
  id: number
  question: React.ReactNode
  answer: React.ReactNode
}[] = [
  {
    id: 1,
    question: (
      <span>
        What is <span className="text-secondary">{SITE_TITLE}</span>?
      </span>
    ),
    // http://Conkoa.ai is an ai co-pilot. Finely tuned with over 100 years of construction knowledge, Conkoa.ai is purpose built to be used by contractors of all sizes to turbo boost their operations.
    answer: (
      <span>
        <span className="text-secondary/90">{SITE_TITLE}</span> is an AI
        co-pilot. Finely tuned with over 100 years of construction knowledge.{' '}
        <span className="text-secondary/90">{SITE_TITLE}</span> is purpose built
        to be used by contractors of all sizes to turbo boost their operations.
      </span>
    ),
  },
  {
    id: 2,
    question: (
      <span>
        What can I do with <span className="text-secondary">{SITE_TITLE}</span>?
      </span>
    ),
    // A better question might be what can’t you do with Conkoa.ai? Contractors are using us to evaluate RFP’s, create bids, write content, evaluate contracts, estimate costs, keep track of projects, and much much more.
    answer: (
      <span>
        A better question might be what can&apos;t you do with{' '}
        <span className="text-secondary/90">{SITE_TITLE}</span>? Contractors are
        using us to evaluate RFP&apos;s, create bids, write content, evaluate
        contracts, estimate costs, keep track of projects, and much much more.
      </span>
    ),
  },
  {
    id: 3,
    question: 'Is my data secure?',
    // When using Conkoa.ai you may add your company’s sensitive data in order to have more detailed discussions. This data is always stored securely and is never shared with others unless we have a legal requirement to do so. We also never use your proprietary data to train our software.
    answer: (
      <span>
        When using <span className="text-secondary/90">{SITE_TITLE}</span> you
        may add your company&apos;s sensitive data in order to have more
        detailed discussions. This data is always stored securely and is never
        shared with others unless we have a legal requirement to do so. We also
        never use your proprietary data to train our software.
      </span>
    ),
  },
  {
    id: 4,
    question: (
      <span>
        How much does <span className="text-secondary">{SITE_TITLE}</span> cost?
      </span>
    ),
    answer: "We're still in beta and haven't yet finalized our pricing model.",
  },
]

export function FrequentlyAskedQuestions() {
  return (
    <div className="mt-28 flex flex-col items-start gap-3 self-stretch rounded-3xl border border-muted-foreground/50 bg-card p-8 sm:p-12 md:pr-0 xl:mt-40">
      <div className="flex w-full flex-col items-start justify-between gap-y-16 md:flex-row">
        <h3 className="flex-1 font-title text-2xl font-semibold md:text-3xl lg:text-4xl">
          Frequently Asked Questions
        </h3>
        <div className="space-10 inline-flex flex-1 flex-col items-start divide-y divide-muted-foreground/50">
          {faqs.map((faq) => (
            <Faq key={faq.id} {...faq} />
          ))}
        </div>
      </div>
    </div>
  )
}

function Faq({
  question,
  answer,
}: {
  question: React.ReactNode
  answer: React.ReactNode
}) {
  return (
    <div className="flex w-full flex-col items-start gap-6 py-6">
      <Collapsible defaultOpen>
        <CollapsibleTrigger asChild aria-controls="radix-:R1jkp:">
          <h4 className="mb-3 flex items-start gap-3 font-title text-xl font-semibold text-foreground">
            <PlusSvg className="mt-1 h-4 w-4" />
            <span className="flex-1">{question}</span>
          </h4>
        </CollapsibleTrigger>
        <CollapsibleContent
          id="radix-:R1jkp:"
          className="overflow-hidden transition-all duration-500 radix-state-closed:animate-collapse-up radix-state-open:animate-collapse-down"
        >
          <p className="ml-7 max-w-3xl pr-6 text-muted-foreground sm:pr-12 sm:text-lg">
            {answer}
          </p>
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}
