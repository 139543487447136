import {
  BarChartIcon,
  CalculatorIcon,
  MessageCircleIcon,
  PencilLineIcon,
  PlusIcon,
  RefreshCwIcon,
  UploadIcon,
  Users2Icon,
} from 'lucide-react'

import { SITE_TITLE } from '~/constants'
import { cn } from '~/utils/misc'

type Feature = {
  text: React.ReactNode
  icon: React.ElementType
}

type Features = [Feature, Feature, Feature, Feature]

export const features1: React.ComponentProps<typeof FeaturesList> = {
  title: 'Over 100 years of construction knowledge that you can tap instantly.',
  description: (
    <span>
      There's so much you can do with{' '}
      <span className="font-medium text-secondary">{SITE_TITLE}</span>
    </span>
  ),
  features: [
    {
      text: 'Easily create well designed content for training, marketing, sales and more.',
      icon: PencilLineIcon,
    },
    {
      text: 'Generate bid responses, project plans, and quotes quickly.',
      icon: CalculatorIcon,
    },
    {
      text: 'Extract insights from documents you upload like price lists, schedules, and contracts.',
      icon: UploadIcon,
    },
    {
      text: 'Evaluate results from completed projects to see where you can improve in the future.',
      icon: BarChartIcon,
    },
  ],
}
export const features2: React.ComponentProps<typeof FeaturesList> = {
  title: (
    <span>
      Your team will need support to learn{' '}
      <span className="font-bold text-secondary">{SITE_TITLE}.</span> We got
      you.
    </span>
  ),
  description: (
    <span>
      <span className="text-secondary">{SITE_TITLE}</span> and AI-copilots in
      general are newer technologies. That's why we offer a comprehensive
      on-boarding experience for your entire team.
    </span>
  ),
  features: [
    {
      text: (
        <span>
          In person training to learn{' '}
          <span className="underline decoration-secondary">how</span> to ask{' '}
          <span className="text-secondary">{SITE_TITLE}</span> the right
          questions.
        </span>
      ),
      icon: Users2Icon,
    },
    {
      text: 'Additional virtual training sessions are included in your subscription.',
      icon: PlusIcon,
    },
    {
      text: 'Ongoing new feature training to help your team stay current.',
      icon: RefreshCwIcon,
    },
    {
      text: 'Live chat & phone support powered by real humans to get you answers quickly.',
      icon: MessageCircleIcon,
    },
  ],
}

// Bid smarter, manage projects efficiently,
// <br />
// and revolutionize your workflow.
export function FeaturesList({
  title,
  description,
  features,
  className,
}: {
  title: React.ReactNode
  description: React.ReactElement
  features: Features
  className?: string
}) {
  return (
    <div className={cn('flex flex-col items-center gap-24', className)}>
      <header className="flex max-w-6xl flex-col items-center gap-12">
        <h2 className="max-w-4xl text-center font-title text-3xl font-semibold leading-tight sm:text-4xl md:text-5xl lg:text-6xl">
          {title}
        </h2>
        <p className="max-w-2xl text-center font-normal text-muted-foreground lg:text-lg">
          {description}
        </p>
      </header>
      <div className="grid items-stretch gap-12 md:grid-cols-2 md:gap-14 lg:gap-20">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  )
}

function FeatureCard({
  text,
  icon,
}: {
  text: React.ReactNode
  icon: React.ElementType
}) {
  const Icon = icon
  return (
    <div className="flex max-w-lg gap-3 rounded-3xl border border-muted-foreground/50 bg-card p-12 lg:max-w-xl">
      <div className="flex flex-col items-start gap-12 self-stretch">
        <Icon className="h-10 w-10 text-muted-foreground/70 md:h-14 md:w-14" />
        <span className="font-title text-2xl font-medium sm:text-3xl xl:text-4xl">
          {text}
        </span>
      </div>
    </div>
  )
}
